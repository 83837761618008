<template>
  <div class="page">
    <div>页面不存在 404</div>
  </div>
</template>

<script>
export default {
  name: "index",
  data() {
    return {}
  },
  methods: {}
}
</script>

<style scoped>
.page {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 100px;
  color: #c1c5c8
}
</style>
